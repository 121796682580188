var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-card',{staticClass:"mx-auto my-12",attrs:{"loading":_vm.requestLoading,"max-width":"550","min-width":"550"}},[_c('v-card-title',{attrs:{"data-cy":"forgotpswd_title"}},[_vm._v(" "+_vm._s(_vm.$t('forgot_pw.title'))+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('forgot_pw.subtitle')))]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('Organization'),"error":_vm.$v.form.organization.$error,"error-messages":_vm.$v.form.organization.$error ?
                (!_vm.$v.form.organization.required ? _vm.$t('validate.required', { field: _vm.$t('Organization') }) :
                  (!_vm.$v.form.organization.minLength ? _vm.$t('validate.invalid', { field: _vm.$t('Organization') }) : '')) : ''},model:{value:(_vm.form.organization),callback:function ($$v) {_vm.$set(_vm.form, "organization", $$v)},expression:"form.organization"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('Username'),"error":_vm.$v.form.username.$error,"error-messages":_vm.$v.form.username.$error ?
                (!_vm.$v.form.username.required ? _vm.$t('validate.required', { field: _vm.$t('Username') }) :
                  (!_vm.$v.form.username.minLength ? _vm.$t('validate.invalid', { field: _vm.$t('Username') }) : '')) : ''},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('Email'),"error":_vm.$v.form.email.$error,"error-messages":_vm.$v.form.email.$error ? (
                !_vm.$v.form.email.required ? _vm.$t('validate.required', { field: _vm.$t('Email') }) :
                (!_vm.$v.form.email.maxLength ? _vm.$t('validate.maxLength', { length: 127 }) :
                  (!_vm.$v.form.email.email ? _vm.$t('validate.invalid', { field: _vm.$t('Email') }) : ''))) : ''},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{staticClass:"ma-2",attrs:{"to":{ name: 'Login' },"data-cy":"back_login"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" "+_vm._s(_vm.$t('forgot_pw.buttons.back_login'))+" ")],1),_c('v-btn',{staticClass:"error ma-2",attrs:{"type":"submit","loading":_vm.requestLoading,"data-cy":"resetpswd_btn"}},[_vm._v(" "+_vm._s(_vm.resetButtonTitle)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }