<template>
  <!-- <v-app id="forgotpassword-app">
        <notifications/>
        <v-main> -->
  <v-container fill-height>
    <v-card
      :loading="requestLoading"
      class="mx-auto my-12"
      max-width="550"
      min-width="550"
    >
      <v-card-title
        data-cy="forgotpswd_title"
      >
        {{ $t('forgot_pw.title') }}
      </v-card-title>
      <v-card-subtitle>{{ $t('forgot_pw.subtitle') }}</v-card-subtitle>
      <v-form @submit.prevent="onSubmit">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                v-model="form.organization"
                :label="$t('Organization')"
                :error="$v.form.organization.$error"
                :error-messages="$v.form.organization.$error ?
                  (!$v.form.organization.required ? $t('validate.required', { field: $t('Organization') }) :
                    (!$v.form.organization.minLength ? $t('validate.invalid', { field: $t('Organization') }) : '')) : ''"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                v-model="form.username"
                :label="$t('Username')"
                :error="$v.form.username.$error"
                :error-messages="$v.form.username.$error ?
                  (!$v.form.username.required ? $t('validate.required', { field: $t('Username') }) :
                    (!$v.form.username.minLength ? $t('validate.invalid', { field: $t('Username') }) : '')) : ''"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                v-model="form.email"
                :label="$t('Email')"
                :error="$v.form.email.$error"
                :error-messages="$v.form.email.$error ? (
                  !$v.form.email.required ? $t('validate.required', { field: $t('Email') }) :
                  (!$v.form.email.maxLength ? $t('validate.maxLength', { length: 127 }) :
                    (!$v.form.email.email ? $t('validate.invalid', { field: $t('Email') }) : ''))) : ''"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn
                class="ma-2"
                :to="{ name: 'Login' }"
                data-cy="back_login"
              >
                <v-icon left>
                  mdi-keyboard-backspace
                </v-icon> {{ $t('forgot_pw.buttons.back_login') }}
              </v-btn>
              <v-btn
                class="error ma-2"
                type="submit"
                :loading="requestLoading"
                data-cy="resetpswd_btn"
              >
                {{ resetButtonTitle }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <!-- <v-card-actions class="text-center">
                    </v-card-actions> -->
    </v-card>
    <!-- <loading :active.sync="requestLoading" :is-full-page="false"></loading> -->
  </v-container>
  <!-- </v-main>
        <v-footer id="footer" class="blue--text">
            {{ $t('footer.copyright') }}
        </v-footer>
    </v-app> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, email, maxLength, minLength } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import Vue from 'vue'

Vue.use(Vuelidate)

export default {
  watch: {
    is_logged_in () {
      this.redirectIfNeeded()
    }
  },
  computed: {
    ...mapGetters('auth', [
      'is_logged_in'
    ])
  },
  data () {
    return {
      // loading: false,
      //   selection: 1,
      form: {
        organization: '',
        username: '',
        email: ''
      },
      resetButtonTitle: this.$t('forgot_pw.buttons.reset'),
      // minLength: 2,
      requestLoading: false,
      isEmailSent: false
      // rules: {
      //     counter: value => value.length <= 127 || 'Max 127 characters',
      //     email: value => {
      //             const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //             return pattern.test(value) || 'Invalid e-mail.'
      //     },
      // },
    }
  },
  validations () {
    return {
      form: {
        organization: {
          required,
          minLength: minLength(2)
        },
        username: {
          required,
          minLength: minLength(2)
        },
        email: {
          required,
          email,
          maxLength: maxLength(127)
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'forgot'
    ]),
    onSubmit () {
      this.$v.form.$touch()

      if (this.$v.form.$invalid) {
        return
      }
      this.requestLoading = true
      this.forgot(this.form).then((unusedRes) => {
        this.requestLoading = false
        this.$notify({
          text: this.$t('forgot_pw.msg.success'),
          type: 'success',
          duration: -1
        })
        this.isEmailSent = true
        this.resetButtonTitle = this.$t('forgot_pw.buttons.email_sent')
      })
        .catch((res) => {
          this.requestLoading = false
          this.$notify({
            text: res != null && res.reset_status === -1 ? this.$t('forgot_pw.msg.incorrect_info') : this.$t('forgot_pw.msg.error'),
            type: 'error'
          })
        })
    },
    redirectIfNeeded () {
      if (this.is_logged_in && this.$route.name !== 'Dashboard') {
        this.$router.replace({ name: 'Dashboard' })
      }
    }
  },
  mounted () {
    this.redirectIfNeeded()
  }
}
</script>

<style>
.v-application {background-image: radial-gradient(circle at 69% 75%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 38%,transparent 38%, transparent 69%,transparent 69%, transparent 100%),radial-gradient(circle at 41% 58%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 3%,transparent 3%, transparent 75%,transparent 75%, transparent 100%),radial-gradient(circle at 94% 91%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 48%,transparent 48%, transparent 55%,transparent 55%, transparent 100%),radial-gradient(circle at 68% 38%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 34%,transparent 34%, transparent 36%,transparent 36%, transparent 100%),radial-gradient(circle at 81% 20%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 40%,transparent 40%, transparent 61%,transparent 61%, transparent 100%),radial-gradient(circle at 46% 37%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 37%,transparent 37%, transparent 76%,transparent 76%, transparent 100%),radial-gradient(circle at 49% 5%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 43%,transparent 43%, transparent 67%,transparent 67%, transparent 100%),radial-gradient(circle at 18% 58%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 4%,transparent 4%, transparent 20%,transparent 20%, transparent 100%),radial-gradient(circle at 43% 68%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 10%,transparent 10%, transparent 36%,transparent 36%, transparent 100%),linear-gradient(135deg, rgb(85, 133, 238),rgb(177, 145, 214));}
.v-footer {background-image: repeating-linear-gradient(45deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(0deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(135deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(90deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),linear-gradient(90deg, rgb(17, 17, 17),rgb(66, 66, 66));}
</style>
